import { MutableRefObject } from 'react';
import {AssetResponse, TalentCard, Order, Asset} from './types';

let openPageViewModalFunc: ((asset: Asset, toDisplayTalent: string) => void) | null = null;

export function setPageViewModalFunc(func: (asset: Asset, toDisplayTalent: string) => void) {
  openPageViewModalFunc = func;
}

export function openPageViewModal(asset: Asset, toDisplayTalent: string) {
  if (openPageViewModalFunc) {
    openPageViewModalFunc(asset, toDisplayTalent);
  }
}