let openAssetModalFunc: Function | null = null;
let openOrderModalFunc: Function | null = null;

export function setOpenAssetModalFunc(func: Function) {
  openAssetModalFunc = func;
}

export function openAssetModal(asset: any, order_id: string, locationPath: string) {
  if (openAssetModalFunc) {
    openAssetModalFunc(asset, order_id, locationPath);
  }
}
