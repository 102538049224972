import { TalentCard } from "./types";
// ModalUtils.ts
let openSameCardsModalFunc: Function | null = null;

export function setOpenSameCardsModalFunc(func: Function) {
  openSameCardsModalFunc = func;
}

export function openSameCardsModal(
  assetName: string, toDisplay: string) {
  if (openSameCardsModalFunc) {
    openSameCardsModalFunc(assetName, toDisplay);
  }
}