import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface WelcomeModalProps {
  onClose: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ onClose }) => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState('');

  

  const [indexLanguage, setIndexLanguage] = useState('en');


  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en';
    setIndexLanguage(storedLanguage);
  }, []);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
  };

  const handleCodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (code === '01121999') {
      onClose();
    } else {
      alert('Incorrect code');
    }
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (termsAccepted && email) {
      try {
        const response = await axios.post('/wp-json/custom/v1/preregistration', { email });
        if (response.status === 200) {
          setThankYouMessage('Thank you for pre-registering! We will notify you when the game launches.');
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      }
    } else {
      alert('Please accept the terms and conditions and enter your email.');
    }
  };

  return (
    <div className="aroundWelcomeModal">

<button 
              className="close-button-modal1" 
              onClick={() => window.location.href=`https://peaxel.me/${indexLanguage}`}>
             <i className="fa-regular fa-circle-xmark"></i>
            </button>
        
      <div className="modal-container-welcome">
     
        <div className="welco1">
        <div className="welco11">
            <img src="https://peaxel.me/wp-content/uploads/2024/06/Peaxel_welcome_page.png"></img>
        </div>
        <div className="welco12">
        {thankYouMessage ? (
          <p>{thankYouMessage}</p>
        ) : (
          <>
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <div>
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={handleTermsChange}
                  required
                />
                
                <label style={{ verticalAlign: 'middle', color: '#ffffffb0!important' }}>
    &nbsp;<a href="https://peaxel.me/en/peaxel-terms-and-conditions/" target="_blank" style={{ color: '#343434' }}> I accept the terms and conditions - GTCU</a>
  </label>

                  </div>
              <button type="submit">Notify me</button>
            </form>
          </>
        )}
        </div>
        </div>
        <div className="welco2">

        <form onSubmit={handleCodeSubmit}>
          <input
            type="text"
            placeholder="Secret key for Beta testing"
            value={code}
            onChange={handleCodeChange}
          />
          <button type="submit">Start Peaxel</button>
        </form>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
