import React, { useEffect, useState } from "react";
import axios from 'axios';
import './App.css'; // Import your CSS file for the modal styles
import { Link } from '@imtbl/imx-sdk';
import { ProviderPreference } from '@imtbl/imx-sdk';


type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};


function Login() {
  const [username, setUsername] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsChecked(e.target.checked);
  };

  const checkUsernameAvailability = async (value: any) => {
    if (value.length < 6) {
      setIsUsernameAvailable(false);
      return;
    }

    

    try {
      const response = await axios.get(`https://peaxel.me/wp-json/custom/v1/check_username?username=${value}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data === 'yes') {
        setIsUsernameAvailable(false);
      } else if (response.data === 'no') {
        setIsUsernameAvailable(true);
      } else {
        //console.log('Issue with the username check');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const isUsernameValid = (value: any) => /^[a-zA-Z0-9@$ _-]+$/.test(value);


  
  
  const handleUsernameChange = (e: any) => {
    const newValue = e.target.value;
    
    // Assume the username is available until proven otherwise
    setIsUsernameAvailable(true);

  
    if (newValue.length < 6 || newValue.length > 30 || !isUsernameValid(newValue) || /\s/.test(newValue)) {
      // If the length is less than 6 or the username is not valid, mark it as unavailable
      setIsUsernameAvailable(false);

    } else {
      // Check the availability only if the length is sufficient and the username is valid
      checkUsernameAvailability(newValue);
   
    }
  
    // Update the state with the new username
    setUsername(newValue);
  }
    
  


  const handleSignUp = async () => {
    if (isUsernameAvailable && isUsernameValid(username)) {
      try {

        const linkIframeOptions: ConfigurableIframeOptions = {
          className: 'my-link-iframe',
        };

        
        // Initialize Link
        let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);
        const providerPreference = ProviderPreference.MAGIC_LINK;
        try {
          // Call the method with a valid provider preference
          let result = await link.setup({providerPreference});

          // Check if the address, email, and providerPreference meet the conditions
          if (
            result.address.length >= 8 &&
            result.email &&
            result.email.length >= 5 &&
            result.email.includes('@') &&
            result.email.includes('.') &&
            result.providerPreference === 'magic_link'
          ) {
            // If all conditions are met, store the data in local storage
            localStorage.setItem('LGaddress', result.address);
            localStorage.setItem('LGstark', result.starkPublicKey);
            localStorage.setItem('LGprovider', result.providerPreference);
            localStorage.setItem('LGnetwork', result.ethNetwork);
            localStorage.setItem('LGemail', result.email);
            localStorage.setItem('LGusername', username);
            localStorage.setItem('LGword','press')
          } else {
            //console.log('Conditions not met.');
          }

          // Print the result
          //console.log(result);
        } catch (error) {
          // Catch and print out the error
          console.error(error);
        }
      } catch (error) {
        //console.log('Error initializing Link:', error);
      }
    } else {
      //console.log('Username is not available');
    }
  }

  const [indexLanguage, setIndexLanguage] = useState('');

useEffect(() => {
  // Get the language from localStorage when the component mounts
  const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
  setIndexLanguage(storedLanguage);
}, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="Center">
      
      <br/><br/>
      <form>
        <div>
        <h2 className="user-registration-title">Welcome to Peaxel</h2>
        <div className="notes">Your username will serve as your unique Talent index.</div><br/>
        
          <input
            className="input-login"
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            placeholder="Username"
          />
        </div>
        <div className="Center">
        {username.length > 0 && (
    <>
          {!isUsernameAvailable && (
            <span className="input-login-info-av">•&nbsp;Username not available</span>
          )}<br/>
           {!isUsernameValid(username) && (
            <span className="input-login-info-va">•&nbsp;Username not valid (min 5 characters, only letters, numbers, @, _, -)</span>
          )}
          </>
  )}
        </div>

        <div className="check-terms">
  <input
    type="checkbox"
    id="termsCheckbox"
    checked={isTermsChecked}
    onChange={handleTermsChange}
  />
  <label htmlFor="termsCheckbox">I agree to the terms and conditions</label>
</div>


        <div>
          <button className="buy-register" type="button" onClick={handleSignUp} disabled={!isUsernameAvailable || !isUsernameValid(username) || /\s/.test(username) || !isTermsChecked}>
            Continue
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
