import { TalentCard } from "./types";

let openOrderModalFunc: Function | null = null;

export function setOpenOrderModalFunc(func: Function) {
  openOrderModalFunc = func;
}

export function openOrderModal(
order_ID: any, cardProp: TalentCard, locationPath: string, activeSection: string) {
  if (openOrderModalFunc) {
    openOrderModalFunc(order_ID, cardProp, locationPath, activeSection); // Pass order_id to the function
  }
}

