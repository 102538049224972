let handleRefreshFunc: (() => void) | null = null;

export function setHandleRefreshFunc(func: () => void) {
  handleRefreshFunc = func;
}

export function handleRefresh() {
  if (handleRefreshFunc) {
    handleRefreshFunc();
  }
}